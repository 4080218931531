import { graphql } from '@/gql/client';

export const LanguageFieldsFragment = graphql(`
  fragment LanguageFieldsFragment on Language {
    id
    code
    name
    slug
  }
`);
