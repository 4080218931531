import React, { ReactNode, useEffect } from 'react';
import cx from 'classnames';
import { Button, Footer, Navbar } from '@stereoagency/ui';
import { useFooterProps, useNavbarProps } from '@/hooks';
import { SideText } from '../SideText';

type Props = {
  preview?: boolean;
  theme?: 'light' | 'dark';
  sidetext?: {
    text: string
    placement?: 'left' | 'right'
  }
  useFixedNavbar?: boolean
  hideFooterDivider?: boolean
  hideSidetext?: boolean
  enableLines?: boolean
  children?: ReactNode
};

export function Layout({
  preview = false, theme = 'light', sidetext, useFixedNavbar, hideFooterDivider, hideSidetext, enableLines, children,
}: Props) {
  const navbarProps = useNavbarProps();
  const footerProps = useFooterProps();

  const handleDisablePreviewMode = () => {
    fetch('/api/preview/disable').then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.classList.remove('light');
      document.body.classList.remove('dark');
      document.body.classList.add(theme);
    }
  }, [theme]);

  return (
    <div className="flex flex-col grow">
      {!!preview && (
        <div className="fixed top-4 left-4 z-40">
          <Button className="text-xs px-3 py-2" onClick={handleDisablePreviewMode}>
            DISABLE PREVIEW MODE
          </Button>
        </div>
      )}
      {!!navbarProps && (
        <Navbar
          className="z-20"
          asFixedOverlay={useFixedNavbar}
          lines={enableLines ? (
            (theme === 'light' && !useFixedNavbar) ? 'dark' : 'light'
          ) : undefined}
          {...navbarProps}
        />
      )}
      <div className="grid grid-cols-1">
        {!!(sidetext?.text && !hideSidetext) && (
          <div className="row-start-1 col-start-1 hidden pointer-events-none xl:flex xl:items-start">
            <div
              className={cx(
                'writing-mode-vertical -scale-x-100 -scale-y-100 font-medium text-[20rem] whitespace-nowrap leading-[1]',
                'sticky top-0 text-gray-mediumlight opacity-20 z-10',
                sidetext.placement !== 'right' && '-translate-x-1/3',
                sidetext.placement === 'right' && 'ml-auto translate-x-1/3',
              )}
            >
              <SideText text={sidetext.text} />
            </div>
          </div>
        )}
        <div className="row-start-1 col-start-1">
          {children}
        </div>
      </div>
      {!!footerProps && (
        <Footer
          className="mt-auto"
          lines={enableLines ? (
            theme === 'light' ? 'dark' : 'light'
          ) : undefined}
          hideDivider={hideFooterDivider}
          {...footerProps}
        />
      )}
    </div>
  );
}
