import React, { useMemo } from 'react';
import {
  FaTwitter, FaFacebookF, FaBloggerB, FaLinkedinIn,
} from 'react-icons/fa';
import { FooterProps, IImage, OfficeInfoBlockProps } from '@stereoagency/ui';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from 'urql';
import flush from 'just-flush';
import Trans from 'next-translate/Trans';
import { useSeoSchema } from './useSeoSchema';
import { useSeoSocial } from './useSeoSocial';
import { useFragment } from '@/gql';
import { MediaItemFieldsFragment, OfficeFieldsFragment } from '@/gql/documents/fragments';
import { useMenu } from './useMenu';
import { FindAllOfficesQueryDocument } from '@/gql/client/graphql';
import { useLangCodeFilter } from './useLangCodeFilter';

export function useFooterProps() {
  const { t } = useTranslation();
  const seoSchema = useSeoSchema();
  const seoSocial = useSeoSocial();
  const legalMenu = useMenu('legal');
  const langCode = useLangCodeFilter();
  const [{ data: officesQuery }] = useQuery({
    query: FindAllOfficesQueryDocument,
    variables: { lang: langCode },
  });
  const officesFields = useFragment(OfficeFieldsFragment, flush(officesQuery?.offices?.nodes ?? []));

  const footerInfo = useMemo(() => (
    <p className="flex flex-col items-start">
      {t('global:get-support')}
      <a href="mailto:info@nviso.eu" className="text-brand-primary-base hover:text-brand-primary-light">info@nviso.eu</a>
    </p>
  ), [t]);

  const footerLogo = useMemo<IImage>(() => {
    const logoFields = useFragment(MediaItemFieldsFragment, seoSchema?.logo);
    if (logoFields?.mediaItemUrl && logoFields.mediaDetails?.width && logoFields.mediaDetails?.height) {
      return {
        src: logoFields.mediaItemUrl,
        width: logoFields.mediaDetails.width,
        height: logoFields.mediaDetails.height,
      };
    }

    return {
      src: 'https://via.placeholder.com/150',
      width: 150,
      height: 150,
    };
  }, [seoSchema?.logo]);

  const footerLegalInfo = useMemo(() => ({
    disclaimer: (
      <Trans
        i18nKey="global:copyright"
        components={{
          // eslint-disable-next-line
          a: <a href="http://stereo.agency" target="_blank" rel="noopner norefrrer" className="underline hover:text-brand-primary-base" />
        }}
        values={{ year: new Date().getUTCFullYear() }}
      />
    ),
    menuItems: legalMenu?.menuItems?.nodes?.map((entry) => ({
      href: entry?.uri ?? '/',
      label: entry?.label ?? '',
    })) ?? [],
  }), [legalMenu]);

  const footerOfficeInfo = useMemo<OfficeInfoBlockProps[]>(() => flush(officesFields.map((entry) => {
    if (!entry.cptOfficeCustomFields?.country) {
      return null;
    }

    const emailList = flush(entry.cptOfficeCustomFields.emailaddresses?.split(/\r|\n/) ?? []);
    const phoneList = flush(entry.cptOfficeCustomFields.phonenumbers?.split(/\r|\n/) ?? []);

    return {
      country: t(`countries:${entry.cptOfficeCustomFields.country}`),
      children: (
        <div className="flex flex-col items-start whitespace-pre-wrap">
          <span>{entry.cptOfficeCustomFields.address}</span>
          <span>
            {emailList.map((email) => (
              <a key={email} href={`mailto:${email}`}>{email}</a>
            ))}
          </span>
          <span>
            {phoneList.map((phone) => (
              <a key={phone} href={`tel:${phone.replace(/\s/g, '')}`}>{phone}</a>
            ))}
          </span>
        </div>
      ),
    };
  })), [officesFields]);

  const footerSocialMenuItems = useMemo(() => {
    const items: FooterProps['socialMenuItems'] = [];

    if (seoSocial?.twitter?.username) {
      items.push({
        href: `https://twitter.com/${seoSocial.twitter.username}`,
        label: seoSocial.twitter.username,
        icon: <FaTwitter />,
        target: '_blank',
      });
    }

    if (seoSocial?.facebook?.url) {
      items.push({
        href: seoSocial.facebook.url,
        label: flush(seoSocial.facebook.url.split('/')).pop() ?? '',
        icon: <FaFacebookF />,
        target: '_blank',
      });
    }

    seoSocial?.otherSocials?.forEach((url) => {
      if (url?.includes('blog.nviso.eu')) {
        items.push({
          href: url,
          label: url.replace(/^http(s)?:\/\//, '').replace(/\/.*$/, ''),
          icon: <FaBloggerB />,
          target: '_blank',
        });
      } else if (url?.includes('twitter.com')) {
        items.push({
          href: url,
          label: url.replace(/^http(s)?:\/\/(www\.)?twitter\.com\//, '').replace(/\/.*$/, ''),
          icon: <FaTwitter />,
          target: '_blank',
        });
      } else if (url?.includes('linkedin.com')) {
        items.push({
          href: url,
          label: seoSchema?.companyName ?? url.replace(/^http(s)?:\/\/(www\.)?linkedin\.com\/company\//, '').replace(/\/.*$/, ''),
          icon: <FaLinkedinIn />,
          target: '_blank',
        });
      }
    });

    return items;
  }, [seoSocial]);

  const footerProps = useMemo<FooterProps | null>(() => ({
    info: footerInfo,
    logo: footerLogo,
    legalInfo: footerLegalInfo,
    officeInfo: footerOfficeInfo,
    socialMenuItems: footerSocialMenuItems,
  }), [footerInfo, footerLogo, footerLegalInfo, footerOfficeInfo]);

  return footerProps;
}
