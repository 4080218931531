/* eslint "no-param-reassign": "off" */
import { SyntheticEvent, useMemo } from 'react';
import { NavbarProps } from '@stereoagency/ui';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useMenu } from './useMenu';
import useWindowLocation from './useWindowLocation';

function transformURL(url: URL, transformer: (url: URL) => URL) {
  return transformer(url);
}

export function useNavbarProps() {
  const router = useRouter();
  const href = useWindowLocation();
  const asPath = router ? router.asPath : '/';
  const { t, lang } = useTranslation();
  const primaryMenuFields = useMenu('primary');

  return useMemo<NavbarProps | null>(() => {
    if (!primaryMenuFields?.menuItems?.nodes?.length) return null;

    const navItems = primaryMenuFields.menuItems.nodes.slice(0, -1);
    const callToAction = primaryMenuFields.menuItems.nodes.slice(-1).pop();

    const currentUrl = new URL(asPath, href ?? 'https://www.nviso.eu');
    const languages = [
      {
        locale: lang,
        href: transformURL(currentUrl, (url) => {
          url.pathname = url.pathname.replace(/^\/de/, '');
          return url;
        }).toString(),
        label: t('languages:english'),
        active: lang === 'en',
        onClick(event: SyntheticEvent<HTMLAnchorElement>) {
          event.preventDefault();
          window.location.assign(event.currentTarget.href);
        }
      },
      {
        locale: lang,
        href: transformURL(currentUrl, (url) => {
          url.pathname = `/de${url.pathname}`;
          return url;
        }).toString(),
        label: t('languages:german'),
        active: lang === 'de',
        onClick(event: SyntheticEvent<HTMLAnchorElement>) {
          event.preventDefault();
          window.location.assign(event.currentTarget.href);
        }
      },
    ];
    const nonActiveLanguage = languages.find((entry) => !entry.active);

    return {
      homeUrl: lang === 'de' ? '/de' : '/',
      languageItems: languages,
      mobileSwitchLanguageAction: nonActiveLanguage
        ? {
            href: nonActiveLanguage.href,
            label: t('global:switch-to', {
              language: nonActiveLanguage.label,
            }),
          }
        : undefined,
      navItems: navItems.map((item) => ({
        href: item?.uri ?? '/',
        label: item?.label ?? '',
        navItems: item?.childItems?.nodes?.map((childItem) => ({
          href: childItem?.uri ?? '/',
          label: childItem?.label ?? '',
          navItems: childItem?.childItems?.nodes?.map((childChildItem) => ({
            href: childChildItem?.uri ?? '/',
            label: childChildItem?.label ?? '',
          })),
        })),
      })),
      callToAction: callToAction
        ? {
            href: callToAction.uri ?? '/',
            label: callToAction.label ?? '',
          }
        : undefined,
      renderMobileHeaderLabel: (item) => {
        const menu = t('global:menu');
        return t('global:back-to', { page: item?.label ?? menu });
      },
    };
  }, [t, primaryMenuFields, href, asPath]);
}
