import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { i18n } from '@/config';
import { FindMenuByLocationQueryDocument } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import { MenuFieldsFragment } from '@/gql/documents/fragments';

export function useMenu(location: 'primary' | 'legal' | 'social') {
  const { lang } = useTranslation();
  const langCode = useMemo(() => lang.split('-')[0].toLowerCase(), [lang]);
  const menuLocationSuffix = useMemo(() => (lang === i18n.defaultLocale ? '' : `___${langCode}`), [langCode]);

  const [{ data }] = useQuery({
    query: FindMenuByLocationQueryDocument,
    variables: { id: `${location}${menuLocationSuffix}` },
  });
  const fields = useFragment(MenuFieldsFragment, data?.menu);
  return fields ?? null;
}
