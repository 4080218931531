import { graphql } from '@/gql/client';

export const MediaItemFieldsFragment = graphql(`
  fragment MediaItemFieldsFragment on MediaItem {
    databaseId
    altText
    mediaItemUrl
    mediaDetails {
      width
      height
    }
  }
`);
