import { ReactNode } from 'react';
import Link from 'next/link';
import compact from 'just-compact';
import redirects from 'redirects.json';
import { LinkComponentProps, LinkContext } from '@stereoagency/ui';
import useTranslation from 'next-translate/useTranslation';

type Props = {
  children?: ReactNode;
};

const langPrefix: Record<string, string | null> = {
  en: null,
  de: 'de',
};

const LinkComponent = ({ href, label, locale, download, target, title, className, onClick, children }: LinkComponentProps) => {
  const { lang } = useTranslation();
  const normalizedHref = href.replace(/https:\/\/\w+\.wpcomstaging\.com/i, '');
  const finalUrl = normalizedHref.match(/^\//)
    ? `/${compact([langPrefix[locale ?? lang], ...normalizedHref.split('/')]).join('/')}`
    : normalizedHref;
  const redir = redirects.find((entry) => entry.source.toLowerCase() === finalUrl.toLowerCase());
  const finalHref = redir?.destination ?? normalizedHref;

  return (
    <Link
      href={redir?.destination ?? normalizedHref}
      download={download}
      locale={locale ?? finalHref.match(/^\/de($|\/)/) ? 'de' : 'en'}
      target={target}
      title={title}
      className={className}
      onClick={onClick}
    >
      {children ?? label}
    </Link>
  );
};

export const LinkContextProviderValue = { LinkComponent };

export function LinkContextProvider({ children }: Props) {
  return <LinkContext.Provider value={LinkContextProviderValue}>{children}</LinkContext.Provider>;
}
