import { useQuery } from 'urql';
import { GetSeoOpenGraphDocument } from '@/gql/client/graphql';

export function useSeoOpenGraph() {
  const [{ data }] = useQuery({
    query: GetSeoOpenGraphDocument,
  });

  return data?.seo?.openGraph ?? null;
}
