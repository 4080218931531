import { useQuery } from 'urql';
import flush from 'just-flush';
import { useMemo } from 'react';
import type { ICallToAction } from '@stereoagency/ui';
import { FindCallToActionsByIdQueryDocument } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import { CallToActionFieldsFragment } from '@/gql/documents/fragments';

export function useCallToActions<T extends boolean>(ids: number[], asMap: T) {
  type ReturnValue = T extends true ? Map<number, ICallToAction> : ICallToAction[];

  const [{ data: callToActionsQuery }] = useQuery({
    query: FindCallToActionsByIdQueryDocument,
    pause: ids.length === 0,
    variables: { ids: useMemo(() => ids.map((id) => String(id)).sort(), [ids]) },
  });
  const callToActionsFields = useFragment(CallToActionFieldsFragment, flush(callToActionsQuery?.callToActions?.nodes ?? []));
  if (typeof window !== 'undefined') {
    console.info('callToActionsFields', callToActionsFields);
  }

  return useMemo<ReturnValue>(() => {
    if (asMap) {
      return new Map(
        callToActionsFields.map((action) => [
          action.databaseId,
          {
            label: action.cptCallToActionCustomFields?.label || action.title || '',
            href: action.cptCallToActionCustomFields?.href?.url ?? '/',
            target: action.cptCallToActionCustomFields?.href?.target ?? undefined,
            style: (action.cptCallToActionCustomFields?.style ?? undefined) as ICallToAction['style'],
            ...(action.cptCallToActionCustomFields?.brandStyle
              ? {
                  brandVariant: action.cptCallToActionCustomFields.brandStyle === 'secondary' ? 'secondary' : 'primary',
                }
              : {}),
            ...(action.cptCallToActionCustomFields?.buttonVariant
              ? {
                  buttonVariant: action.cptCallToActionCustomFields.buttonVariant === 'secondary' ? 'secondary' : 'primary',
                }
              : {}),
          },
        ])
      ) as ReturnValue;
    }
    return callToActionsFields.map((action) => ({
      label: action.cptCallToActionCustomFields?.label || action.title || '',
      href: action.cptCallToActionCustomFields?.href?.url ?? '/',
      target: action.cptCallToActionCustomFields?.href?.target ?? undefined,
      style: (action.cptCallToActionCustomFields?.style ?? undefined) as ICallToAction['style'],
      ...(action.cptCallToActionCustomFields?.brandStyle
        ? {
            brandVariant: action.cptCallToActionCustomFields.brandStyle === 'secondary' ? 'secondary' : 'primary',
          }
        : {}),
      ...(action.cptCallToActionCustomFields?.buttonVariant
        ? {
            buttonVariant: action.cptCallToActionCustomFields.buttonVariant === 'secondary' ? 'secondary' : 'primary',
          }
        : {}),
    })) as ReturnValue;
  }, [callToActionsFields]);
}
