import { graphql } from '@/gql/client';

export const CallToActionFieldsFragment = graphql(`
  fragment CallToActionFieldsFragment on CallToAction {
    databaseId
    title
    cptCallToActionCustomFields {
      label
      style
      href {
        target
        title
        url
      }
      brandStyle
      buttonVariant
    }
  }
`);
