import { IImage } from '@stereoagency/ui';
import { MediaItemFieldsFragmentFragment } from '@/gql/client/graphql';

export function mediaItemAsImage(item: MediaItemFieldsFragmentFragment, override?: Partial<IImage>): IImage | null {
  if (item.mediaItemUrl && item.mediaDetails?.width && item.mediaDetails.height) {
    return {
      src: item.mediaItemUrl,
      width: item.mediaDetails.width,
      height: item.mediaDetails.height,
      alt: item.altText ?? '',
      ...override,
    };
  }

  return null;
}
