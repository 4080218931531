import { graphql } from '@/gql/client';

export const TermNodeFieldsFragment = graphql(`
  fragment TermNodeFieldsFragment on TermNode {
    id
    databaseId
    slug
    name
    uri
  }
`);
