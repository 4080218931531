import { useQuery } from 'urql';
import { GetSeoSchemaQueryDocument } from '@/gql/client/graphql';

export function useSeoSchema() {
  const [{ data }] = useQuery({
    query: GetSeoSchemaQueryDocument,
  });

  return data?.seo?.schema ?? null;
}
