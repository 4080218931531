import React, { ReactNode, useMemo } from 'react';
import Image from 'next/image';
import { ImageComponentProps, ImageContext } from '@stereoagency/ui';

type Props = {
  children?: ReactNode
};

const ImageComponent = ({
  alt, width, height, src, objectFit, objectPosition, priority, fill, sizes, blurDataURL, className,
}: ImageComponentProps) => {
  const placeholderUrl = useMemo(() => {
    if (!blurDataURL && src) {
      return `https://i0.wp.com/${src.replace(/^http(s)?:\/\//, '')}?w=10&quality=50&strip=all&filter=blurgaussian&ssl=1`;
    }

    if (blurDataURL) {
      return blurDataURL;
    }

    return undefined;
  }, [blurDataURL]);

  return (
    <Image
      priority={priority}
      fill={fill}
      sizes={sizes ?? '100vw'}
      src={src}
      alt={alt ?? ''}
      width={width}
      height={height}
      placeholder={placeholderUrl ? 'blur' : 'empty'}
      blurDataURL={placeholderUrl}
      className={className}
      style={{
        objectFit,
        objectPosition,
      }}
    />
  );
};
const ImageContextProviderValue = { ImageComponent };

export function ImageContextProvider({ children }: Props) {
  return (
    <ImageContext.Provider value={ImageContextProviderValue}>
      {children}
    </ImageContext.Provider>
  );
}
