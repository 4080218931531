import React, { useMemo } from 'react';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useSeoOpenGraph, useSeoSchema } from '@/hooks';
import { FragmentType, useFragment } from '@/gql';
import { MediaItemFieldsFragment, PostTypeSeoFieldsFragment } from '@/gql/documents/fragments';
import { normalizeUrl } from '@/utils';

type Props = {
  title?: string;
  description?: string;
  seo?: FragmentType<typeof PostTypeSeoFieldsFragment>;
};

export function SeoHead({ title, description, seo }: Props) {
  const { lang } = useTranslation();
  const seoSchema = useSeoSchema();
  const seoOpenGraph = useSeoOpenGraph();
  const defaultOgImageFields = useFragment(MediaItemFieldsFragment, seoOpenGraph?.defaultImage);
  const seoFields = useFragment(PostTypeSeoFieldsFragment, seo);
  const opengraphImageFields = useFragment(MediaItemFieldsFragment, seoFields?.opengraphImage);

  const seoTitle = useMemo(
    () => `${title ?? seoFields?.opengraphTitle ?? seoFields?.title ?? seoOpenGraph?.frontPage?.title ?? seoSchema?.siteName}`,
    [title, seoOpenGraph, seoSchema]
  );
  const seoDescription = useMemo(
    () => description ?? seoFields?.opengraphDescription ?? seoFields?.metaDesc ?? seoOpenGraph?.frontPage?.description ?? '',
    [description, seoOpenGraph]
  );
  const ogImage = useMemo(() => {
    if (opengraphImageFields?.mediaItemUrl) {
      return {
        url: opengraphImageFields.mediaItemUrl,
        width: opengraphImageFields.mediaDetails?.width ?? 500,
        height: opengraphImageFields.mediaDetails?.height ?? 400,
      };
    }

    if (defaultOgImageFields?.mediaItemUrl) {
      return {
        url: defaultOgImageFields.mediaItemUrl,
        width: defaultOgImageFields.mediaDetails?.width ?? 500,
        height: defaultOgImageFields.mediaDetails?.height ?? 400,
      };
    }

    return {
      url: 'https://newnviso.wpcomstaging.com/wp-content/uploads/2023/07/HOME.jpg',
      width: 1200,
      height: 675,
    };
  }, [defaultOgImageFields, opengraphImageFields]);

  return (
    <NextSeo
      title={seoTitle}
      description={seoDescription}
      nofollow={seoFields?.metaRobotsNofollow === 'nofollow' || process.env.NO_INDEX === '1'}
      noindex={seoFields?.metaRobotsNoindex === 'noindex' || process.env.NO_INDEX === '1'}
      canonical={seoFields?.canonical ? normalizeUrl(seoFields.canonical) : undefined}
      openGraph={{
        siteName: seoFields?.opengraphSiteName ?? seoSchema?.siteName ?? undefined,
        title: seoTitle,
        description: seoDescription,
        images: ogImage ? [ogImage] : [],
        locale: lang,
        article:
          seoFields?.opengraphType === 'article'
            ? {
                modifiedTime: seoFields.opengraphModifiedTime ?? undefined,
                publishedTime: seoFields.opengraphPublishedTime ?? undefined,
                authors: seoFields.opengraphAuthor ? [seoFields.opengraphAuthor] : undefined,
              }
            : undefined,
        type: seoFields?.opengraphType ?? undefined,
      }}
      additionalLinkTags={[
        { rel: 'icon', href: '/favicon.ico', sizes: 'any' },
        { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
        { rel: 'manifest', href: '/manifest.webmanifest' },
      ]}
    />
  );
}
