import flush from 'just-flush';
import createThrottle from 'p-throttle';
import { cacheExchange, ClientOptions, createClient, fetchExchange, type ssrExchange } from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';

const throttle = createThrottle({
  limit: 5,
  interval: 1000,
});

const throttledFetch = throttle((...args: Parameters<typeof fetch>) => fetch(...args));

export const clientFactory = (ssrCache: ReturnType<typeof ssrExchange> | null = null, options: Omit<ClientOptions, 'url' | 'exchanges'> = {}) =>
  createClient({
    url: process.env.WP_GQL_ENDPOINT,
    fetchOptions: {
      headers: process.env.WP_REFRESH_TOKEN
        ? {
            Authorization: `Bearer ${process.env.WP_REFRESH_TOKEN}`,
          }
        : {},
    },
    requestPolicy: 'cache-first',
    fetch: throttledFetch,
    exchanges: flush([
      cacheExchange,
      ssrCache,
      retryExchange({
        initialDelayMs: 2000,
        maxDelayMs: 30000,
        randomDelay: true,
        maxNumberAttempts: 5,
        retryIf: err => {
          console.warn(err);
          return !!(err && err.networkError)
        },
      }),
      typeof window !== 'undefined' ? null : fetchExchange,
    ]),
    ...options,
  });
