import { graphql } from '@/gql/client';

export const ServiceSummaryFieldsFragment = graphql(`
  fragment ServiceSummaryFieldsFragment on Service {
    id
    databaseId
    slug
    uri
    title
    excerpt
    featuredImage {
      node {
        ...MediaItemFieldsFragment
      }
    }
    language {
      ...LanguageFieldsFragment
    }
    tags(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    categories(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    cptServiceCustomFields {
      category
      videoBackgroundUrl
    }
  }
`);
