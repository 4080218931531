import { graphql } from '@/gql/client';

export const JobSummaryFieldsFragment = graphql(`
  fragment JobSummaryFieldsFragment on Job {
    id
    databaseId
    slug
    uri
    title
    excerpt
    language {
      ...LanguageFieldsFragment
    }
    roleExperience(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    tags(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    categories(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    cptJobFields {
      isRemote
      office {
        ... on Office {
          id
          databaseId
          cptOfficeCustomFields {
            country
          }
        }
      }
    }
  }
`);
