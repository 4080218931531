/* eslint "no-await-in-loop": "off" */
import { ComponentType } from 'react';
import { Client, Provider, ssrExchange } from 'urql';
import ssrPrepass from 'react-ssr-prepass';
import I18nProvider from 'next-translate/I18nProvider';
import { RouterContext } from 'next/dist/shared/lib/router-context';
import { type GraphQLError } from 'graphql';
import { clientFactory } from '@/gql';
import { ImageContextProvider, Layout, LinkContextProvider } from '@/components';
import { i18n } from '@/config';

export type SSRData = Record<
string,
{
  hasNext?: boolean;
  data?: string | undefined;
  extensions?: string | undefined;
  error?: {
    graphQLErrors: Array<Partial<GraphQLError> | string>;
    networkError?: string;
  };
}
>

type BaseOptions<Params> = {
  params?: Params;
  locale?: string;
  preview?: boolean;
};

type Options<Params, Props> = BaseOptions<Params> & {
  propsFactory: (
    input: BaseOptions<Params> & {
      urqlClient: Client;
    }
  ) => Promise<
    | Props
    | {
        notFound: true;
      }
  >;
  Component: ComponentType<Props>;
};

export async function withPrepass<Params extends {}, Props extends {}>({
  params,
  locale,
  preview,
  propsFactory,
  Component,
}: Options<Params, Props>) {
  const ssrCache = ssrExchange({ isClient: false });
  const client = clientFactory(ssrCache, { suspense: true });
  const props = await propsFactory({
    locale,
    params,
    preview,
    urqlClient: client,
  });

  if ('notFound' in props) {
    return {
      revalidate: 3600 * 6,
      notFound: props.notFound,
    };
  }
  let shouldRepass = false;
  do {
    // perform prepass
    await ssrPrepass(
      // eslint-disable-next-line
      <RouterContext.Provider value={require('next-router-mock')}>
        <LinkContextProvider>
          <ImageContextProvider>
            <I18nProvider lang={locale ?? i18n.defaultLocale}>
              <Provider value={client}>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Provider>
            </I18nProvider>
          </ImageContextProvider>
        </LinkContextProvider>
      </RouterContext.Provider>
    );
    // remove any error states
    const urqlState = ssrCache.extractData();
    const entries = Object.entries(urqlState);
    // eslint-disable-next-line
    for (const [key, value] of entries) {
      if (value.error?.networkError) {
        console.warn(value.error);
        delete urqlState[key];
      }
    }
    ssrCache.restoreData(urqlState);
    shouldRepass = entries.length !== Object.keys(urqlState).length;
  } while (shouldRepass);

  const urqlState: SSRData = ssrCache.extractData();
  Object.values(urqlState).forEach((entry) => {
    if (entry.error) {
      console.error(JSON.stringify(entry.error, null, 2));
    }
  });

  return {
    revalidate: 3600 * 6,
    props: {
      urqlState,
      ...props,
    },
  };
}
