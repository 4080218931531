import { graphql } from '@/gql/client';

export const PostFieldsFragment = graphql(`
  fragment PostFieldsFragment on Post {
    ...PostSummaryFieldsFragment
    content
    seo {
      ...PostTypeSeoFieldsFragment
    }
    pageCustomFields {
      sidetext {
        text
        placement
      }
      enableOverlayNavbar
      enableLines
      theme
      hideFooterDivider
      hideSidetext
    }
  }
`);
