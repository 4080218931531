import { graphql } from '@/gql/client';

export const PostSummaryFieldsFragment = graphql(`
  fragment PostSummaryFieldsFragment on Post {
    id
    databaseId
    dateGmt
    slug
    uri
    title
    excerpt
    featuredImage {
      node {
        ...MediaItemFieldsFragment
      }
    }
    language {
      ...LanguageFieldsFragment
    }
    author {
      node {
        name
      }
    }
    tags(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    categories(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
  }
`);
