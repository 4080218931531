import { graphql } from '@/gql/client';

export const JobFieldsFragment = graphql(`
  fragment JobFieldsFragment on Job {
    ...JobSummaryFieldsFragment
    content
    seo {
      ...PostTypeSeoFieldsFragment
    }
    pageCustomFields {
      sidetext {
        text
        placement
      }
      enableOverlayNavbar
      enableLines
      theme
      hideFooterDivider
      hideSidetext
    }
  }
`);
