import { graphql } from '@/gql/client';

export const PageSummaryFieldsFragment = graphql(`
  fragment PageSummaryFieldsFragment on Page {
    id
    databaseId
    dateGmt
    slug
    uri
    title
    featuredImage {
      node {
        ...MediaItemFieldsFragment
      }
    }
    language {
      ...LanguageFieldsFragment
    }
  }
`);
