import { useEffect, useState } from 'react';

export default function useWindowLocation() {
  const [href, setHref] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHref(window.location.href);
    }
  }, []);

  return href;
}
