import { graphql } from '@/gql/client';

export const EventSummaryFieldsFragment = graphql(`
  fragment EventSummaryFieldsFragment on Event {
    id
    databaseId
    slug
    uri
    title
    featuredImage {
      node {
        ...MediaItemFieldsFragment
      }
    }
    language {
      ...LanguageFieldsFragment
    }
    tags(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    categories(first: 1000) {
      nodes {
        ...TermNodeFieldsFragment
      }
    }
    cptEventCustomFields {
      datetime
      country
      isOnline
      callToAction {
        __typename
        ... on CallToAction {
          title
          cptCallToActionCustomFields {
            label
            style
            href {
              target
              title
              url
            }
            brandStyle
            buttonVariant
          }
        }
      }
    }
  }
`);
