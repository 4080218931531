/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\n  fragment CallToActionFieldsFragment on CallToAction {\n    databaseId\n    title\n    cptCallToActionCustomFields {\n      label\n      style\n      href {\n        target\n        title\n        url\n      }\n      brandStyle\n      buttonVariant\n    }\n  }\n": types.CallToActionFieldsFragmentFragmentDoc,
    "\n  fragment EventFieldsFragment on Event {\n    ...EventSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n": types.EventFieldsFragmentFragmentDoc,
    "\n  fragment EventSummaryFieldsFragment on Event {\n    id\n    databaseId\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptEventCustomFields {\n      datetime\n      country\n      isOnline\n      callToAction {\n        __typename\n        ... on CallToAction {\n          title\n          cptCallToActionCustomFields {\n            label\n            style\n            href {\n              target\n              title\n              url\n            }\n            brandStyle\n            buttonVariant\n          }\n        }\n      }\n    }\n  }\n": types.EventSummaryFieldsFragmentFragmentDoc,
    "\n  fragment JobFieldsFragment on Job {\n    ...JobSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n": types.JobFieldsFragmentFragmentDoc,
    "\n  fragment JobSummaryFieldsFragment on Job {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    language {\n      ...LanguageFieldsFragment\n    }\n    roleExperience(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptJobFields {\n      isRemote\n      office {\n        ... on Office {\n          id\n          databaseId\n          cptOfficeCustomFields {\n            country\n          }\n        }\n      }\n    }\n  }\n": types.JobSummaryFieldsFragmentFragmentDoc,
    "\n  fragment LanguageFieldsFragment on Language {\n    id\n    code\n    name\n    slug\n  }\n": types.LanguageFieldsFragmentFragmentDoc,
    "\n  fragment MediaItemFieldsFragment on MediaItem {\n    databaseId\n    altText\n    mediaItemUrl\n    mediaDetails {\n      width\n      height\n    }\n  }\n": types.MediaItemFieldsFragmentFragmentDoc,
    "\n  fragment MenuFieldsFragment on Menu {\n    id\n    databaseId\n    menuItems(first: 1000, where:{parentDatabaseId:0}) {\n      nodes {\n        id\n        databaseId\n        label\n        uri\n\n        # we'll allow 3 levels\n        childItems(first: 1000) {\n          nodes {\n            id\n            databaseId\n            label\n            uri\n\n            childItems(first: 1000) {\n              nodes {\n                id\n                databaseId\n                label\n                uri\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.MenuFieldsFragmentFragmentDoc,
    "\n  fragment OfficeFieldsFragment on Office {\n    id\n    databaseId\n    title\n    cptOfficeCustomFields {\n      country\n      address\n      emailaddresses\n      phonenumbers\n    }\n  }\n": types.OfficeFieldsFragmentFragmentDoc,
    "\n  fragment PageFieldsFragment on Page {\n    ...PageSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n": types.PageFieldsFragmentFragmentDoc,
    "\n  fragment PageSummaryFieldsFragment on Page {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n  }\n": types.PageSummaryFieldsFragmentFragmentDoc,
    "\n  fragment PostFieldsFragment on Post {\n    ...PostSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n": types.PostFieldsFragmentFragmentDoc,
    "\n  fragment PostSummaryFieldsFragment on Post {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    author {\n      node {\n        name\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n": types.PostSummaryFieldsFragmentFragmentDoc,
    "\n  fragment PostTypeSeoFieldsFragment on PostTypeSEO {\n    title\n    metaDesc\n    canonical\n    metaRobotsNofollow\n    metaRobotsNoindex\n    opengraphTitle\n    opengraphAuthor\n    opengraphDescription\n    opengraphImage {\n      ...MediaItemFieldsFragment\n    }\n    opengraphModifiedTime\n    opengraphPublisher\n    opengraphPublishedTime\n    opengraphSiteName\n    opengraphType\n    opengraphUrl\n    readingTime\n  }\n": types.PostTypeSeoFieldsFragmentFragmentDoc,
    "\n  fragment ServiceFieldsFragment on Service {\n    ...ServiceSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n": types.ServiceFieldsFragmentFragmentDoc,
    "\n  fragment ServiceSummaryFieldsFragment on Service {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptServiceCustomFields {\n      category\n      videoBackgroundUrl\n    }\n  }\n": types.ServiceSummaryFieldsFragmentFragmentDoc,
    "\n  fragment TermNodeFieldsFragment on TermNode {\n    id\n    databaseId\n    slug\n    name\n    uri\n  }\n": types.TermNodeFieldsFragmentFragmentDoc,
    "\n  mutation updatePageContentMutation($id: ID!, $content: String!) {\n    updatePage(input: {\n      id: $id,\n      content: $content\n    }) {\n      page {\n        id\n        content\n      }\n    }\n  }\n": types.UpdatePageContentMutationDocument,
    "\n  mutation updateServiceContentMutation($id: ID!, $content: String!) {\n    updateService(input: {\n      id: $id,\n      content: $content\n    }) {\n      service {\n        id\n        content\n      }\n    }\n  }\n": types.UpdateServiceContentMutationDocument,
    "\n  query findAllCategoriesQuery($first: Int = 1000) {\n    categories(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n": types.FindAllCategoriesQueryDocument,
    "\n  query findAllEventSummariesQuery($first: Int = 1000, $afterEventDatetime: String, $lang: LanguageCodeFilterEnum = EN) {\n    events(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: ACF_DATETIME\n          order: ASC\n        }\n        afterEventDatetime: $afterEventDatetime\n      }\n    ) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllEventSummariesQueryDocument,
    "\n  query findAllJobSummariesQuery($first: Int = 1000) {\n    jobs(first: $first) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllJobSummariesQueryDocument,
    "\n  query findAllOfficesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = ALL) {\n    offices(first: $first, where:{language:$lang}) {\n      nodes {\n        ...OfficeFieldsFragment\n      }\n    }\n  }\n": types.FindAllOfficesQueryDocument,
    "\n  query findAllPageSummariesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllPageSummariesQueryDocument,
    "\n  query findAllPagesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageFieldsFragment\n      }\n    }\n  }\n": types.FindAllPagesQueryDocument,
    "\n  query findAllPostSummariesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: DATE\n          order: DESC\n        }\n      }\n    ) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllPostSummariesQueryDocument,
    "\n  query findAllPostsQuery($first: Int = 1000) {\n    posts(first: $first) {\n      nodes {\n        ...PostFieldsFragment\n      }\n    }\n  }\n": types.FindAllPostsQueryDocument,
    "\n  query findAllServiceSummariesByIdsQuery($ids: [ID!]!, $first: Int = 1000) {\n    services(first: $first, where:{ in: $ids }) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllServiceSummariesByIdsQueryDocument,
    "\n  query findAllServiceSummariesQuery($first: Int = 1000) {\n    services(first: $first) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindAllServiceSummariesQueryDocument,
    "\n  query findAllTagsQuery($first: Int = 1000) {\n    tags(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n": types.FindAllTagsQueryDocument,
    "\n  query findCallToActionsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    callToActions(first: $first, where:{in:$ids}) {\n      nodes {\n        ...CallToActionFieldsFragment\n      }\n    }\n  }\n": types.FindCallToActionsByIdQueryDocument,
    "\n  query findEventDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    event(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...EventFieldsFragment\n    }\n  }\n": types.FindEventDetailByIdQueryDocument,
    "\n  query findEventSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    events(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindEventSummariesForSlugOrIdQueryDocument,
    "\n  query findJobDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    job(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...JobFieldsFragment\n    }\n  }\n": types.FindJobDetailByIdQueryDocument,
    "\n  query findJobSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    jobs(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindJobSummariesForSlugOrIdQueryDocument,
    "\n  query findMediaItemDetailByIdQuery($id: ID!) {\n    mediaItem(id: $id, idType: DATABASE_ID) {\n      ...MediaItemFieldsFragment\n    }\n  }\n": types.FindMediaItemDetailByIdQueryDocument,
    "\n  query findMediaItemDetailsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    mediaItems(first: $first, where:{in:$ids}) {\n      nodes {\n        ...MediaItemFieldsFragment\n      }\n    }\n  }\n": types.FindMediaItemDetailsByIdQueryDocument,
    "\n  query findMenuByLocationQuery($id: ID!) {\n    menu(id: $id, idType: LOCATION) {\n      ...MenuFieldsFragment\n    }\n  }\n": types.FindMenuByLocationQueryDocument,
    "\n  query findPageDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    page(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PageFieldsFragment\n    }\n  }\n": types.FindPageDetailByIdQueryDocument,
    "\n  query findPageSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    pages(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindPageSummariesForSlugOrIdQueryDocument,
    "\n  query findPostDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    post(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PostFieldsFragment\n    }\n  }\n": types.FindPostDetailByIdQueryDocument,
    "\n  query findPostSummariesForCategorySlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, categoryName: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindPostSummariesForCategorySlugQueryDocument,
    "\n  query findPostSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    posts(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindPostSummariesForSlugOrIdQueryDocument,
    "\n  query findPostSummariesForTagSlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, tag: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindPostSummariesForTagSlugQueryDocument,
    "\n  query findRelatedPostSummariesQuery($postId: ID!, $categories: [ID!]!, $first: Int = 1000) {\n    posts(first: $first, where:{notIn:[$postId], categoryIn: $categories, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindRelatedPostSummariesQueryDocument,
    "\n  query findRoleExperienceOrderQuery($first: Int = 1000) {\n    roleExperience(first: $first, where:{orderby:TERM_ORDER, order: ASC}) {\n      nodes {\n        id\n        databaseId\n        name\n      }\n    }\n  }\n": types.FindRoleExperienceOrderQueryDocument,
    "\n  query findServiceDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    service(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...ServiceFieldsFragment\n    }\n  }\n": types.FindServiceDetailByIdQueryDocument,
    "\n  query findServiceDetailBySlugQuery($id: ID!) {\n    service(id: $id, idType: SLUG) {\n      ...ServiceFieldsFragment\n    }\n  }\n": types.FindServiceDetailBySlugQueryDocument,
    "\n  query findServiceSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    services(first: $first, where:{id:$databaseId, name:$slug, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n": types.FindServiceSummariesForSlugOrIdQueryDocument,
    "\n  query findTranslationsForPageByIdQuery($id: ID!) {\n    page(id: $id, idType: DATABASE_ID) {\n      id\n      databaseId\n      language { ...LanguageFieldsFragment }\n      translations {\n        id\n        databaseId\n        language { ...LanguageFieldsFragment }\n      }\n    }\n  }\n": types.FindTranslationsForPageByIdQueryDocument,
    "\n  query getReadingSettingsQuery {\n    readingSettings {\n      pageOnFront\n    }\n  }\n": types.GetReadingSettingsQueryDocument,
    "\n  query getSeoOpenGraph {\n    seo {\n      openGraph {\n        defaultImage {\n          ...MediaItemFieldsFragment\n        }\n        frontPage {\n          title\n          description\n          image {\n            ...MediaItemFieldsFragment\n          }\n        }\n      }\n    }\n  }\n": types.GetSeoOpenGraphDocument,
    "\n  query getSeoSchemaQuery {\n    seo {\n      schema {\n        siteName\n        companyName\n        logo {\n          ...MediaItemFieldsFragment\n        }\n      }\n    }\n  }\n": types.GetSeoSchemaQueryDocument,
    "\n  query getSeoSocialQuery {\n    seo {\n      social {\n        facebook { url }\n        twitter { username }\n        otherSocials\n      }\n    }\n  }\n": types.GetSeoSocialQueryDocument,
};

export function graphql(source: "\n  fragment CallToActionFieldsFragment on CallToAction {\n    databaseId\n    title\n    cptCallToActionCustomFields {\n      label\n      style\n      href {\n        target\n        title\n        url\n      }\n      brandStyle\n      buttonVariant\n    }\n  }\n"): (typeof documents)["\n  fragment CallToActionFieldsFragment on CallToAction {\n    databaseId\n    title\n    cptCallToActionCustomFields {\n      label\n      style\n      href {\n        target\n        title\n        url\n      }\n      brandStyle\n      buttonVariant\n    }\n  }\n"];
export function graphql(source: "\n  fragment EventFieldsFragment on Event {\n    ...EventSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"): (typeof documents)["\n  fragment EventFieldsFragment on Event {\n    ...EventSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"];
export function graphql(source: "\n  fragment EventSummaryFieldsFragment on Event {\n    id\n    databaseId\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptEventCustomFields {\n      datetime\n      country\n      isOnline\n      callToAction {\n        __typename\n        ... on CallToAction {\n          title\n          cptCallToActionCustomFields {\n            label\n            style\n            href {\n              target\n              title\n              url\n            }\n            brandStyle\n            buttonVariant\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment EventSummaryFieldsFragment on Event {\n    id\n    databaseId\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptEventCustomFields {\n      datetime\n      country\n      isOnline\n      callToAction {\n        __typename\n        ... on CallToAction {\n          title\n          cptCallToActionCustomFields {\n            label\n            style\n            href {\n              target\n              title\n              url\n            }\n            brandStyle\n            buttonVariant\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment JobFieldsFragment on Job {\n    ...JobSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"): (typeof documents)["\n  fragment JobFieldsFragment on Job {\n    ...JobSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"];
export function graphql(source: "\n  fragment JobSummaryFieldsFragment on Job {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    language {\n      ...LanguageFieldsFragment\n    }\n    roleExperience(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptJobFields {\n      isRemote\n      office {\n        ... on Office {\n          id\n          databaseId\n          cptOfficeCustomFields {\n            country\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment JobSummaryFieldsFragment on Job {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    language {\n      ...LanguageFieldsFragment\n    }\n    roleExperience(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptJobFields {\n      isRemote\n      office {\n        ... on Office {\n          id\n          databaseId\n          cptOfficeCustomFields {\n            country\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment LanguageFieldsFragment on Language {\n    id\n    code\n    name\n    slug\n  }\n"): (typeof documents)["\n  fragment LanguageFieldsFragment on Language {\n    id\n    code\n    name\n    slug\n  }\n"];
export function graphql(source: "\n  fragment MediaItemFieldsFragment on MediaItem {\n    databaseId\n    altText\n    mediaItemUrl\n    mediaDetails {\n      width\n      height\n    }\n  }\n"): (typeof documents)["\n  fragment MediaItemFieldsFragment on MediaItem {\n    databaseId\n    altText\n    mediaItemUrl\n    mediaDetails {\n      width\n      height\n    }\n  }\n"];
export function graphql(source: "\n  fragment MenuFieldsFragment on Menu {\n    id\n    databaseId\n    menuItems(first: 1000, where:{parentDatabaseId:0}) {\n      nodes {\n        id\n        databaseId\n        label\n        uri\n\n        # we'll allow 3 levels\n        childItems(first: 1000) {\n          nodes {\n            id\n            databaseId\n            label\n            uri\n\n            childItems(first: 1000) {\n              nodes {\n                id\n                databaseId\n                label\n                uri\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MenuFieldsFragment on Menu {\n    id\n    databaseId\n    menuItems(first: 1000, where:{parentDatabaseId:0}) {\n      nodes {\n        id\n        databaseId\n        label\n        uri\n\n        # we'll allow 3 levels\n        childItems(first: 1000) {\n          nodes {\n            id\n            databaseId\n            label\n            uri\n\n            childItems(first: 1000) {\n              nodes {\n                id\n                databaseId\n                label\n                uri\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment OfficeFieldsFragment on Office {\n    id\n    databaseId\n    title\n    cptOfficeCustomFields {\n      country\n      address\n      emailaddresses\n      phonenumbers\n    }\n  }\n"): (typeof documents)["\n  fragment OfficeFieldsFragment on Office {\n    id\n    databaseId\n    title\n    cptOfficeCustomFields {\n      country\n      address\n      emailaddresses\n      phonenumbers\n    }\n  }\n"];
export function graphql(source: "\n  fragment PageFieldsFragment on Page {\n    ...PageSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"): (typeof documents)["\n  fragment PageFieldsFragment on Page {\n    ...PageSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"];
export function graphql(source: "\n  fragment PageSummaryFieldsFragment on Page {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  fragment PageSummaryFieldsFragment on Page {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  fragment PostFieldsFragment on Post {\n    ...PostSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"): (typeof documents)["\n  fragment PostFieldsFragment on Post {\n    ...PostSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"];
export function graphql(source: "\n  fragment PostSummaryFieldsFragment on Post {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    author {\n      node {\n        name\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PostSummaryFieldsFragment on Post {\n    id\n    databaseId\n    dateGmt\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    author {\n      node {\n        name\n      }\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment PostTypeSeoFieldsFragment on PostTypeSEO {\n    title\n    metaDesc\n    canonical\n    metaRobotsNofollow\n    metaRobotsNoindex\n    opengraphTitle\n    opengraphAuthor\n    opengraphDescription\n    opengraphImage {\n      ...MediaItemFieldsFragment\n    }\n    opengraphModifiedTime\n    opengraphPublisher\n    opengraphPublishedTime\n    opengraphSiteName\n    opengraphType\n    opengraphUrl\n    readingTime\n  }\n"): (typeof documents)["\n  fragment PostTypeSeoFieldsFragment on PostTypeSEO {\n    title\n    metaDesc\n    canonical\n    metaRobotsNofollow\n    metaRobotsNoindex\n    opengraphTitle\n    opengraphAuthor\n    opengraphDescription\n    opengraphImage {\n      ...MediaItemFieldsFragment\n    }\n    opengraphModifiedTime\n    opengraphPublisher\n    opengraphPublishedTime\n    opengraphSiteName\n    opengraphType\n    opengraphUrl\n    readingTime\n  }\n"];
export function graphql(source: "\n  fragment ServiceFieldsFragment on Service {\n    ...ServiceSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"): (typeof documents)["\n  fragment ServiceFieldsFragment on Service {\n    ...ServiceSummaryFieldsFragment\n    content\n    seo {\n      ...PostTypeSeoFieldsFragment\n    }\n    pageCustomFields {\n      sidetext {\n        text\n        placement\n      }\n      enableOverlayNavbar\n      enableLines\n      theme\n      hideFooterDivider\n      hideSidetext\n    }\n  }\n"];
export function graphql(source: "\n  fragment ServiceSummaryFieldsFragment on Service {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptServiceCustomFields {\n      category\n      videoBackgroundUrl\n    }\n  }\n"): (typeof documents)["\n  fragment ServiceSummaryFieldsFragment on Service {\n    id\n    databaseId\n    slug\n    uri\n    title\n    excerpt\n    featuredImage {\n      node {\n        ...MediaItemFieldsFragment\n      }\n    }\n    language {\n      ...LanguageFieldsFragment\n    }\n    tags(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    categories(first: 1000) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n    cptServiceCustomFields {\n      category\n      videoBackgroundUrl\n    }\n  }\n"];
export function graphql(source: "\n  fragment TermNodeFieldsFragment on TermNode {\n    id\n    databaseId\n    slug\n    name\n    uri\n  }\n"): (typeof documents)["\n  fragment TermNodeFieldsFragment on TermNode {\n    id\n    databaseId\n    slug\n    name\n    uri\n  }\n"];
export function graphql(source: "\n  mutation updatePageContentMutation($id: ID!, $content: String!) {\n    updatePage(input: {\n      id: $id,\n      content: $content\n    }) {\n      page {\n        id\n        content\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updatePageContentMutation($id: ID!, $content: String!) {\n    updatePage(input: {\n      id: $id,\n      content: $content\n    }) {\n      page {\n        id\n        content\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation updateServiceContentMutation($id: ID!, $content: String!) {\n    updateService(input: {\n      id: $id,\n      content: $content\n    }) {\n      service {\n        id\n        content\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateServiceContentMutation($id: ID!, $content: String!) {\n    updateService(input: {\n      id: $id,\n      content: $content\n    }) {\n      service {\n        id\n        content\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllCategoriesQuery($first: Int = 1000) {\n    categories(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllCategoriesQuery($first: Int = 1000) {\n    categories(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllEventSummariesQuery($first: Int = 1000, $afterEventDatetime: String, $lang: LanguageCodeFilterEnum = EN) {\n    events(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: ACF_DATETIME\n          order: ASC\n        }\n        afterEventDatetime: $afterEventDatetime\n      }\n    ) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllEventSummariesQuery($first: Int = 1000, $afterEventDatetime: String, $lang: LanguageCodeFilterEnum = EN) {\n    events(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: ACF_DATETIME\n          order: ASC\n        }\n        afterEventDatetime: $afterEventDatetime\n      }\n    ) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllJobSummariesQuery($first: Int = 1000) {\n    jobs(first: $first) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllJobSummariesQuery($first: Int = 1000) {\n    jobs(first: $first) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllOfficesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = ALL) {\n    offices(first: $first, where:{language:$lang}) {\n      nodes {\n        ...OfficeFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllOfficesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = ALL) {\n    offices(first: $first, where:{language:$lang}) {\n      nodes {\n        ...OfficeFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllPageSummariesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllPageSummariesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllPagesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllPagesQuery($first: Int = 1000) {\n    pages(first: $first) {\n      nodes {\n        ...PageFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllPostSummariesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: DATE\n          order: DESC\n        }\n      }\n    ) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllPostSummariesQuery($first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(\n      first: $first,\n      where: {\n        language: $lang,\n        orderby: {\n          field: DATE\n          order: DESC\n        }\n      }\n    ) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllPostsQuery($first: Int = 1000) {\n    posts(first: $first) {\n      nodes {\n        ...PostFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllPostsQuery($first: Int = 1000) {\n    posts(first: $first) {\n      nodes {\n        ...PostFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllServiceSummariesByIdsQuery($ids: [ID!]!, $first: Int = 1000) {\n    services(first: $first, where:{ in: $ids }) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllServiceSummariesByIdsQuery($ids: [ID!]!, $first: Int = 1000) {\n    services(first: $first, where:{ in: $ids }) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllServiceSummariesQuery($first: Int = 1000) {\n    services(first: $first) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllServiceSummariesQuery($first: Int = 1000) {\n    services(first: $first) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findAllTagsQuery($first: Int = 1000) {\n    tags(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findAllTagsQuery($first: Int = 1000) {\n    tags(first: $first) {\n      nodes {\n        ...TermNodeFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findCallToActionsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    callToActions(first: $first, where:{in:$ids}) {\n      nodes {\n        ...CallToActionFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findCallToActionsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    callToActions(first: $first, where:{in:$ids}) {\n      nodes {\n        ...CallToActionFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findEventDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    event(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...EventFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findEventDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    event(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...EventFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findEventSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    events(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findEventSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    events(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...EventSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findJobDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    job(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...JobFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findJobDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    job(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...JobFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findJobSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    jobs(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findJobSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    jobs(first: $first, where:{name:$slug, id:$databaseId, stati:[DRAFT, PUBLISH]}) {\n      nodes {\n        ...JobSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findMediaItemDetailByIdQuery($id: ID!) {\n    mediaItem(id: $id, idType: DATABASE_ID) {\n      ...MediaItemFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findMediaItemDetailByIdQuery($id: ID!) {\n    mediaItem(id: $id, idType: DATABASE_ID) {\n      ...MediaItemFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findMediaItemDetailsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    mediaItems(first: $first, where:{in:$ids}) {\n      nodes {\n        ...MediaItemFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findMediaItemDetailsByIdQuery($ids: [ID!]!, $first: Int = 1000) {\n    mediaItems(first: $first, where:{in:$ids}) {\n      nodes {\n        ...MediaItemFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findMenuByLocationQuery($id: ID!) {\n    menu(id: $id, idType: LOCATION) {\n      ...MenuFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findMenuByLocationQuery($id: ID!) {\n    menu(id: $id, idType: LOCATION) {\n      ...MenuFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findPageDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    page(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PageFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findPageDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    page(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PageFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findPageSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    pages(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findPageSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    pages(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PageSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findPostDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    post(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PostFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findPostDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    post(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...PostFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findPostSummariesForCategorySlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, categoryName: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findPostSummariesForCategorySlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, categoryName: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findPostSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    posts(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findPostSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    posts(first: $first, where:{name:$slug, id: $databaseId, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findPostSummariesForTagSlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, tag: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findPostSummariesForTagSlugQuery($slug: String, $first: Int = 1000, $lang: LanguageCodeFilterEnum = EN) {\n    posts(first: $first, where:{language: $lang, tag: $slug, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findRelatedPostSummariesQuery($postId: ID!, $categories: [ID!]!, $first: Int = 1000) {\n    posts(first: $first, where:{notIn:[$postId], categoryIn: $categories, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findRelatedPostSummariesQuery($postId: ID!, $categories: [ID!]!, $first: Int = 1000) {\n    posts(first: $first, where:{notIn:[$postId], categoryIn: $categories, orderby:{field:DATE,order:DESC}}) {\n      nodes {\n        ...PostSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findRoleExperienceOrderQuery($first: Int = 1000) {\n    roleExperience(first: $first, where:{orderby:TERM_ORDER, order: ASC}) {\n      nodes {\n        id\n        databaseId\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query findRoleExperienceOrderQuery($first: Int = 1000) {\n    roleExperience(first: $first, where:{orderby:TERM_ORDER, order: ASC}) {\n      nodes {\n        id\n        databaseId\n        name\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findServiceDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    service(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...ServiceFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findServiceDetailByIdQuery($id: ID!, $asPreview: Boolean) {\n    service(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {\n      ...ServiceFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findServiceDetailBySlugQuery($id: ID!) {\n    service(id: $id, idType: SLUG) {\n      ...ServiceFieldsFragment\n    }\n  }\n"): (typeof documents)["\n  query findServiceDetailBySlugQuery($id: ID!) {\n    service(id: $id, idType: SLUG) {\n      ...ServiceFieldsFragment\n    }\n  }\n"];
export function graphql(source: "\n  query findServiceSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    services(first: $first, where:{id:$databaseId, name:$slug, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query findServiceSummariesForSlugOrIdQuery($slug: String, $databaseId: Int, $first: Int = 1000) {\n    services(first: $first, where:{id:$databaseId, name:$slug, stati: [DRAFT, PUBLISH]}) {\n      nodes {\n        ...ServiceSummaryFieldsFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query findTranslationsForPageByIdQuery($id: ID!) {\n    page(id: $id, idType: DATABASE_ID) {\n      id\n      databaseId\n      language { ...LanguageFieldsFragment }\n      translations {\n        id\n        databaseId\n        language { ...LanguageFieldsFragment }\n      }\n    }\n  }\n"): (typeof documents)["\n  query findTranslationsForPageByIdQuery($id: ID!) {\n    page(id: $id, idType: DATABASE_ID) {\n      id\n      databaseId\n      language { ...LanguageFieldsFragment }\n      translations {\n        id\n        databaseId\n        language { ...LanguageFieldsFragment }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getReadingSettingsQuery {\n    readingSettings {\n      pageOnFront\n    }\n  }\n"): (typeof documents)["\n  query getReadingSettingsQuery {\n    readingSettings {\n      pageOnFront\n    }\n  }\n"];
export function graphql(source: "\n  query getSeoOpenGraph {\n    seo {\n      openGraph {\n        defaultImage {\n          ...MediaItemFieldsFragment\n        }\n        frontPage {\n          title\n          description\n          image {\n            ...MediaItemFieldsFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSeoOpenGraph {\n    seo {\n      openGraph {\n        defaultImage {\n          ...MediaItemFieldsFragment\n        }\n        frontPage {\n          title\n          description\n          image {\n            ...MediaItemFieldsFragment\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getSeoSchemaQuery {\n    seo {\n      schema {\n        siteName\n        companyName\n        logo {\n          ...MediaItemFieldsFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSeoSchemaQuery {\n    seo {\n      schema {\n        siteName\n        companyName\n        logo {\n          ...MediaItemFieldsFragment\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getSeoSocialQuery {\n    seo {\n      social {\n        facebook { url }\n        twitter { username }\n        otherSocials\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSeoSocialQuery {\n    seo {\n      social {\n        facebook { url }\n        twitter { username }\n        otherSocials\n      }\n    }\n  }\n"];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;