
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/tailwind.css';
import '@stereoagency/soehne/index.css';
import '@stereoagency/ui/lib/global.css';
import '@egjs/react-flicking/dist/flicking.css';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { motion, AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ImageContextProvider, LinkContextProvider } from '@/components';
import { type SSRData } from '@/utils/withPrepass';
import { URQLProvider } from '@/gql';
type Props = {
    urqlState?: SSRData;
};
if (typeof window !== 'undefined' && process.env.PIWIK_CONTAINER_ID && process.env.PIWIK_CONTAINER_URL) {
    PiwikPro.initialize(process.env.PIWIK_CONTAINER_ID, process.env.PIWIK_CONTAINER_URL);
}
function App({ Component, pageProps }: AppProps<Props>) {
    const router = useRouter();
    return (<>
      <Script id="polyfill" strategy="beforeInteractive" src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=es6%2CResizeObserver%2CIntl.DateTimeFormat%2CIntl.DisplayNames%2CIntl.ListFormat%2CIntl.Locale%2CIntl.NumberFormat%2CIntl.PluralRules%2CIntl.RelativeTimeFormat%2CIntl.getCanonicalLocales"/>
      {!!process.env.GTM_ID && (<Script id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GTM_ID}');`}
        </Script>)}
      <LinkContextProvider>
        <ImageContextProvider>
            <AnimatePresence initial={false} mode="wait">
              <motion.div key={router?.asPath} className="flex grow" initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{
            opacity: 1
        }}>
                <URQLProvider urqlState={pageProps.urqlState}>
                  <Component {...pageProps}/>
                </URQLProvider>
              </motion.div>
            </AnimatePresence>
        </ImageContextProvider>
      </LinkContextProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  