import { useQuery } from 'urql';
import flush from 'just-flush';
import { useMemo } from 'react';
import { FindMediaItemDetailsByIdQueryDocument } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import { MediaItemFieldsFragment } from '@/gql/documents/fragments';

export function useMediaItems(ids: number[]) {
  const [{ data: mediaItemsQueryResult }] = useQuery({
    query: FindMediaItemDetailsByIdQueryDocument,
    variables: { ids: ids.map((id) => String(id)).sort() },
  });
  const mediaItemFields = useFragment(MediaItemFieldsFragment, flush(mediaItemsQueryResult?.mediaItems?.nodes ?? []));
  return useMemo(() => {
    type EntryType = (typeof mediaItemFields)[number];
    const map = new Map<number, EntryType>();
    mediaItemFields.forEach((item) => {
      map.set(item.databaseId, item);
    });
    return map;
  }, [mediaItemFields]);
}
