import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { LinkContextProviderValue } from '@/components/LinkContextProvider';

const { LinkComponent } = LinkContextProviderValue;

const parseOptions: HTMLReactParserOptions = {
  replace(node) {
    if ('attribs' in node && node.tagName.toLowerCase() === 'a') {
      return (
        <LinkComponent href={node.attribs.href} target={node.attribs.target} download={node.attribs.download === '1'}>
          {domToReact(node.children, parseOptions)}
        </LinkComponent>
      );
    }
    return false;
  },
};

export function parseHtml(input: string): string | JSX.Element | JSX.Element[] {
  return parse(input, parseOptions);
}
