import * as htmlparser2 from 'htmlparser2';
import compact from 'just-compact';
import { lastIndexOf } from './lastIndexOf';

export function extractHeadingsFromHtml(html: string, tags?: string[]) {
  const entries: {
    type: string
    text: string,
    closed: boolean
  }[] = [];
  const parser = new htmlparser2.Parser({
    onopentag: (name) => {
      if (
        (!tags?.length && name.match(/^h\d/))
        || tags?.includes(name)
      ) {
        entries.push({ type: name, text: '', closed: false });
      }
    },
    ontext: (text) => {
      const lastIndex = lastIndexOf(entries, (entry) => !entry.closed);
      if (lastIndex > -1) entries[lastIndex].text += text;
    },
    onclosetag: (name) => {
      if (name.match(/^h\d/)) {
        const lastIndex = lastIndexOf(entries, (entry) => (
          !entry.closed
          && entry.type === name
        ));
        if (lastIndex > -1) {
          entries[lastIndex].closed = true;
        }
      }
    },
  });
  parser.write(html);
  return compact(entries.filter((entry) => entry.closed).map(({ text }) => text));
}
