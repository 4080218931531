import { ReactNode } from 'react';
import { ssrExchange, Provider } from 'urql';
import { SSRData } from '@/utils/withPrepass';
import { clientFactory } from './clientFactory';

type Props = {
  urqlState?: SSRData;
  children?: ReactNode;
};

export default function URQLProvider({ urqlState, children }: Props) {
  const ssrCache = ssrExchange({
    isClient: true,
    staleWhileRevalidate: false,
    initialState: urqlState ?? {},
  });

  const client = clientFactory(ssrCache, { suspense: false });

  return (
    <Provider key={JSON.stringify(urqlState)} value={client}>
      {children}
    </Provider>
  );
}
