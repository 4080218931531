import { graphql } from '@/gql/client';

export const OfficeFieldsFragment = graphql(`
  fragment OfficeFieldsFragment on Office {
    id
    databaseId
    title
    cptOfficeCustomFields {
      country
      address
      emailaddresses
      phonenumbers
    }
  }
`);
