import { graphql } from '@/gql/client';

export const PostTypeSeoFieldsFragment = graphql(`
  fragment PostTypeSeoFieldsFragment on PostTypeSEO {
    title
    metaDesc
    canonical
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphTitle
    opengraphAuthor
    opengraphDescription
    opengraphImage {
      ...MediaItemFieldsFragment
    }
    opengraphModifiedTime
    opengraphPublisher
    opengraphPublishedTime
    opengraphSiteName
    opengraphType
    opengraphUrl
    readingTime
  }
`);
