import React from 'react';

type Props = {
  text: string
};

export function SideText({ text }: Props) {
  return (
    <p>{text}</p>
  );
}
