import { useQuery } from 'urql';
import { GetSeoSocialQueryDocument } from '@/gql/client/graphql';

export function useSeoSocial() {
  const [{ data }] = useQuery({
    query: GetSeoSocialQueryDocument,
  });

  return data?.seo?.social ?? null;
}
