import { graphql } from '@/gql/client';

export const MenuFieldsFragment = graphql(`
  fragment MenuFieldsFragment on Menu {
    id
    databaseId
    menuItems(first: 1000, where:{parentDatabaseId:0}) {
      nodes {
        id
        databaseId
        label
        uri

        # we'll allow 3 levels
        childItems(first: 1000) {
          nodes {
            id
            databaseId
            label
            uri

            childItems(first: 1000) {
              nodes {
                id
                databaseId
                label
                uri
              }
            }
          }
        }
      }
    }
  }
`);
